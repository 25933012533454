<template>
    <div>
        <!-- 面包屑导航区域 这个组件的样式其他组件也用的到 所以写到公共样式里 -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> <!-- 点击回到welcome 因为重定向 -->
            <el-breadcrumb-item>数据采集</el-breadcrumb-item>
            <el-breadcrumb-item>筛查列表</el-breadcrumb-item>
        </el-breadcrumb>

        <!-- 卡片视图区域 -->
        <el-card>
            <!-- layout栅格 设置多少行多少列 span是宽度 gutter是间隙 -->
            <el-row :gutter="5">
                <el-col :span="7">
                    <div class="block">
                        <span class="demonstration">检查时间：</span>
                        <el-date-picker v-model="timeObj" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
                            start-placeholder="开始日期" end-placeholder="结束日期" @change="changeDate">
                        </el-date-picker>
                    </div>
                </el-col>
                <el-col :span="6">
                    <el-input v-model="queryInfo.name" placeholder="请输入内容" clearable @clear="getUserList()"></el-input>
                </el-col>
                <el-col :span="2"><el-button type="primary" icon="el-icon-search"
                        @click="getUserList()">搜索</el-button></el-col>
            </el-row>

            <!-- 用户列表区域 -->
            <el-table :data="list" border stripe>
                <!-- 跟menu一样 把要展示的数据存储到table自带的属性data里面 下面再用prop取对应的数据 和v-model双向绑定 -->

                <el-table-column type="index"></el-table-column> <!-- column索引列 只要加上type="index" -->
                <el-table-column label="用户名" prop="userName"></el-table-column>
                <el-table-column label="年级" prop="gradeName"></el-table-column>
                <el-table-column label="班级" prop="className"></el-table-column>
                <el-table-column label="左足弓类型" prop="archTypeL">
                </el-table-column>
                <el-table-column label="右足弓类型" prop="archTypeR">
                </el-table-column>
                <el-table-column label="左脚图片" prop="imgL">
                    <template slot-scope="scope">
                        <img style="height: 50px;transform: scaleX(-1);" :src="scope.row.imgL" />
                    </template>
                </el-table-column>
                <el-table-column label="右脚图片" prop="imgR">
                    <template slot-scope="scope">
                        <img style="height: 50px" :src="scope.row.imgR" />
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" prop="createTime"></el-table-column>
                
                <!-- <el-table-column label="操作" width="180px">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-edit" size="mini" @click="amend(scope.row)"></el-button>
                        <el-button type="danger" icon="el-icon-delete" size="mini"
                            @click="removeUserById(scope.row.id)"></el-button>
                    </template>
                </el-table-column> -->

            </el-table>
            <!-- 分页区域 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="queryInfo.page" :page-sizes="[1, 2, 5, 10]" :page-size="queryInfo.pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total"> <!-- layout表示显示哪些东西 -->
            </el-pagination>

        </el-card>





    </div>
</template>

<script>
export default {
    data() {
        return {
            queryInfo: {
                name: '',
                page: 1,
                pageSize: 10,
                type: 1,
                startTime:'',
                endTime:''
            },
            timeObj: "",
            list: [], /* 返回的数据存储到这里 */
            total: 0,
            addDialogVisible: false,
            addForm: {
                name: '',
                mobile: '',
                updateTime: ''
            },
        }
    },
    created() {
        this.getUserList()
    },
    methods: {
        changeDate(e){
            this.queryInfo.startTime = e[0];
            this.queryInfo.endTime = e[1];
            this.getUserList();
        },
       

        amend(data) {
            this.addForm = JSON.parse(JSON.stringify(data))
            this.addDialogVisible = true;
        },
        // --------请求方法-----
        async getUserList() {
            const { data: res } = await this.$http.get('/AgileInstrument/getList', {
                params: this.queryInfo /* 将参数放到data里面 因为下面还要根据选择的条数等重新请求 参数会变 */
            })
            if (res.code != 1) {
                return this.$message.error('获取用户列表失败！')
            }
            this.list = res.data.records
            
            this.total = res.data.total
        },
        handleSizeChange(newSize) {
            // console.log(newSize)
            this.queryInfo.pageSize = newSize
            this.getUserList()
        },
        handleCurrentChange(newPage) {
            console.log(newPage)
            this.queryInfo.page = newPage
            this.getUserList()
        },
    }
}
</script>

<style></style>